@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  // GERAL

    body { min-width: 320px;}
    select::-ms-expand {
      display: none;
    }

  //THEME

  //HEADER 
  #section-header {
  //   .header__right-up,
  //   .header__right-down {
  //    width: auto;
  // }
    .flex__w-1-3 {
      flex: 1 0 2rem;
    }

    .header__language { padding: 1.95rem; }

    .header__right-down { height: 51%;}

    .dashboard-toggle {
      height: 100%;
    }

  }

  //FOOTER 

  #section-footer {
    margin: 0;
  }
  // HOMEPAGE

  .weather-app {
    @include breakpoint(tablet) {
      font-size: $font-size-quaternary;
    }
  }

  .homepage__first-row{
   @include breakpoint(tablet) {
      height: 235px;
    }
  }   
  .homepage__second-row{
    @include breakpoint(tablet) {
      padding-top: .5rem;
    }
  }

  #homepage-section, 
  #homepage-section .portlet-layout>div,
  #homepage-section .portlet-layout>div>div,
  #homepage-section .portlet-layout>div>div>div, 
  #homepage-section .portlet-layout .portlet, 
  #homepage-section .portlet-layout .portlet-content, 
  #homepage-section .portlet-layout .portlet-content-container,
  #homepage-section .portlet-layout .portlet-body,
  #homepage-section .portlet-layout .cube,
  #homepage-section .portlet-layout .journal-content-article, 
  #homepage-section .weather-app, 
  #homepage-section .slick-slider,
  #homepage-section .slick-slider .slick-list,
  #homepage-section .slick-slider .slick-track, 
  #homepage-section .slick-slider .slick-track>div, 
  #homepage-section .slick-slider .slick-track>div>div,
  #homepage-section .slick-slider .slick-track>div>div>div, 
  #homepage-section .slick-slider .slick-track>div>div>div>div{

    @include breakpoint(tablet) {
    height:100%!important
  }
  } 

  #column-5 .cube-container,
  #column-6 .cube-container {
    height: 240px;

    @include breakpoint(tablet) {
      height: 180px;
    }
  }

  #column-3 .cube-container,
  #column-4 .cube-container {
    @include breakpoint(tablet) {
      height: 180px;
    }
  }

  .cube {
    &-container {
      -webkit-perspective: none;
      perspective: none;
    }

    &-rotation {
      -webkit-transform-style: inherit;
      transform-style: inherit;
    }

    &__front {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }

    &__back {
      display: none;
    }

    &__front,
    &__back,
    &-rotation:hover,
    &-rotation--responsive {
      -webkit-transform: none;
      transform: none;
      -webkit-transition: display 0.3s;
      transition: display 0.3s;
    }

    &-rotation {
      &:hover {
        > .cube__front {
          display: none;
        }

        > .cube__back {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
        }
      }
    }

    &.cube-rotation--responsive {
      @include breakpoint(tablet) {
       .cube__front { display: none;}
       .cube__back { display: flex;}
      }
    }

    .box__invert, .square-box, .rectangle-box {
      height: 100%;
    }

    .box__invert--container {
      justify-content: center;
    }

    .ie-padding-box  {
      padding: 3rem 0;
    }
    .box__invert, .square-box, .rectangle-box, .news__container,
     .news__footer { 
      width: 100%;
    }
    .news__icon {width: 11px;}

    .news__more {
      float: right;
      width: 10rem;
     }

     .news__article {
       padding: 2%;
     }

     .news__container {
       height: 300px;
     }

     .news__header { flex: 0;}

  }

  #cube__news {
    .cube__news {
      width: 100%;
      .cube__back {
        @include breakpoint(tablet) {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex
        }
      }
    }
  }

// CIDADÃOS

  .mosaic {
    &-item {
      justify-content: space-around;
      padding: 2.5rem;
    }

    &__heading {
      width: 100%;
    }
  }

  /*SEARCH */

  .search-top-field input {
    min-width: 190px;
  }


  //AUTENTICAÇÃO

  .authcard {
    height: 250px;
    @include breakpoint(desktop) {
      min-height: 250px; 
    }
    p {width: 100%}
  }

  .panel-heading-custom {
    height:70px;
  
    @include breakpoint(tablet) {
      width: 100%; 
      height:auto;
    }
    svg {
      flex-shrink: 0;
      width: 18px;

      // @include breakpoint(desktop) {
      //   margin-right: 2.5rem; 
      // }
  
    }

    span {
      flex: 0 1 auto;
    }
  }

  .serviceboxed {
    &__info {
      width: 100%;
    }

    &__buttons {
      .button {
        flex: 1 0 auto;
      }
    }
  }
}
