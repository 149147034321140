#section-page-header {
  min-height: 77px;

  @include breakpoint(tablet) {
    min-height: 63px;
  }
}
  .page {
    &-header {
    
      &__heading {
        font-size: 26px;
        font-weight: 600;
        margin: 0;
        letter-spacing: .7px;
        line-height: 1.2;

        @include breakpoint(tablet) {
          font-size: 15px;
          line-height: 20px;
        }

        @include breakpoint(phone) {
          font-size: 13px;
          line-height: 20px;
          text-align: center;
        }
      }

      &__breadcrumb {
        font-size: 11px;
      }

      &__title {
        padding: 2.3rem 4.5rem;

        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 15px;
          height: 15px;
          line-height: 0;
          background-color: $color-white;
          z-index: 1;
 
          @include breakpoint(tablet) {
            bottom: -1px;
            left: -1px;
          }

          @include breakpoint(phone) {
            display: none;
          }
      
        }
      }
    }

    &-info {
      // padding: .25rem 1rem;
      background-color: $color-white;
      margin-bottom: 1.5rem; 

      .p__wide {
        padding: 3rem;
        padding-right: 40%;

          @media (max-width: 991px) { 
            padding-right: 3rem;
           }
      }
    }
  }

  .sub-header{
    color: $color-grey-dark-3;
    text-transform: uppercase; 
    font-weight: 300;
    font-size:$font-size-tertiary;    
  }

  .main-header{
    color: $color-grey-dark-3;
    text-transform: uppercase;
    font-weight: 900; 
    font-size:$font-size-septenary    
  }

