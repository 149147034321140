//Modulo loading
.searchColumn{
    position: relative;
}

.searchLoading{
    display: none;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index:5000;
    background: rgba(255, 255, 255, 0.8);
    img{
        position: fixed;
        top: 45%;
        right: 35%;
    }
}

//Modulo de pesquisa no header
.search-top{    
    width: 100%
}
.search-top form{
    margin: 0 auto;

    > * {
    line-height: 18px;
      }
    @include breakpoint(tablet) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
      }

      @include breakpoint(smallest-phone) {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
      }
}
.search-top-field{
    vertical-align: middle;
}
.search-top .form-inline{
    display:inline-block;

    @include breakpoint(tablet) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin-bottom: 1rem; 
      }
      @include breakpoint(smallest-phone) {
        margin-bottom: 2rem; 
      }
}

.search-top label{
    font-weight:400;
    margin: 0
}

.search-top input{
    padding:5px;
    font-size: $font-size-primary;
   
}
.search-top .search-top-btn{
    background-color: transparent;
    border:none;
    width: 30px;
    height: 30px;
    margin-top: 2px;
    display: inline-block; 
    vertical-align: middle;

    @include breakpoint(tablet) {
        display: inline-block !important;
        padding: 0.5rem !important;
       }
}

.search-top .search-top-btn:hover svg{
    color:$color-secondary;
}

.search-top .icon-search{
    
    width: 18px;
    height: 18px;
    color:$color-black;
}

.search-top-field input {
    min-width: 290px;

    &:focus {
        background-color: $color-grey-light-6;
    }

    @include breakpoint(large-desktop) {
        min-width: 200px;
        width: 200px;
      }

      @include breakpoint(tablet) {
       display: -webkit-box;
       display: -ms-flexbox;
       display: flex;
      }
}

#mobileForm {
    .searchForm .form-inline {
        display: flex;
        justify-content: center !important;
}

    .select-template:before {
        @include breakpoint(tablet) {
            right: 60px;
           }
    }
}
//Auto complete pesquisa
.search-form{
    position:relative;
}

.box-list-suggestions{
    z-index: 2001;
    position:absolute;
    top:100%; 
    right:32px;
    background-color:$color-white;
    -webkit-transition: all ease-out 350ms;
    transition: all ease-out 350ms;
    -webkit-box-shadow: 0px 2px 15px -1px rgba(0,0,0,0.15);
    box-shadow: 0px 2px 15px -1px rgba(0,0,0,0.15);

    ul {
        padding:0; 
    }
    ul li{
        display:block;  
        color:$color-grey-dark-3;
    }
    ul li a{
        display:block;  
        color:$color-grey-dark-3;
        font-size:$font-size-quaternary;
        padding:2px 10px   
    }
    ul li a:hover{
        text-decoration:none
    }
    .listSuggestionsItens li a:hover{
        color:$color-white;
        background-color:$color-secondary-dark
    }

    @include breakpoint(tablet) {
        top:79px;
        right:30px;

        .listSuggestionsItens li a{
            padding:2px 10px!important;
            justify-content:left!important;
        }
    }
    
    @include breakpoint(smallest-phone) {
        top:90px;
    }
}

.list-suggestions-open{
    display:block
}



//Página de resultados
.search-main-col {
    padding: 46px 0 100px 0;

    .service__heading{
        margin-top: 0
    }
}

.search-result{
    font-size: $font-size-tertiary;
    color:$color-grey-dark-2;
    padding-top:8px;
    display:block;    

    .search-result-num{
        color:$color-secondary;
        font-weight:900;
        font-size:$font-size-septenary;
        padding-right:5px;
    }

    .search-result-text span{
        font-weight:900;
        font-size:$font-size-septenary;
        text-transform:uppercase;
        padding-right:5px;
        color:$color-grey-dark-3;
    }
    
}

.search-list{

    .search-all-type{
        font-size:$font-size-primary;
        font-weight:normal;
        color:$color-grey-dark-1;

    }

    .search-list-title{
        text-transform: uppercase;
        font-weight:900;
        font-size:$font-size-tertiary;

    }

    .search-item{
        padding-top: 1rem;
        padding-bottom:20px;
        border-top: 1px solid $color-grey-light-8;

        .search-item-title a, 
        .search-item-title{
            font-size:$font-size-primary;
            font-weight:900;
            color:$color-secondary
        }

        .search-item-title-no-larch-item{
        padding-top: 1rem;
        padding-bottom:20px;
        border-top: 1px solid $color-grey-light-8;

        .search-item-title a, 
        .search-item-title{
            font-size:$font-size-primary;
            font-weight:900;
            color:$color-secondary
        }

        .search-item-title-no-link{
            font-size:$font-size-primary;
            font-weight:900;
            color:$color-grey-dark-3;

        }

        &-description {
            font-weight: 400;
        }
        
        } 
    }
    .search-item:first-child{
        border-top:none;
    }
    .search-item-links a{
        text-decoration:underline;
        border-left:1px solid $color-secondary;
        padding:0 5px;
        color:$color-secondary
    }
    .search-item-links a:first-child{
        border-left:none;
        padding-left:0;
    }
    .search-item-link-title{
        font-weight:900;
        color:$color-secondary;
        padding-right:5px;
    }
    .search-item-img{
        width:100%;
        height:100px;
        overflow: hidden;

        img{
            max-width: 90%;
        }
    }

    .search-item-info{
        text-transform:uppercase;
        color:$color-grey-dark-1;
        font-size:$font-size-secondary
    }
    .search-item-tags .tag{
        background-color: $color-grey-light-5;
        color:$color-grey-dark-1;
        display:inline-block;
        padding:4px 8px;
        margin-right:10px;
        font-size: $font-size-quinary        
    }

    .search-item-local {
        $font-size-secondary: 12px; 
    }
    .search-item-local .nav__icon{
        width:18px;
        height:18px;
        vertical-align: middle
    }
    .search-item-local a{
        display: inline-block;
        vertical-align: middle
    }
    .news-Title{
        margin-top:10px
    }
    .item-news{
        overflow: hidden;
    }

    .item-promoted{
        vertical-align: middle;
        display: inline-block;
        height: 21px;
        width: 21px;
        margin-right: 5px;
    }
    .item-promoted svg{
        width: 21px;
        height:21px        
    }
    .search-item-local-phone, 
    .search-item-local-email,´
    .search-item-local-url, 
    .search-item-local-available,
    .search-item-local-availableText
    {
        display:inline-block;
        line-height: 23px;
        vertical-align: middle;
    }
}


.filter{
    
    &:before, &:after{content:''; display:table}
    &:after{clear:both}

    @include breakpoint(tablet) {
        background-color: $color-grey-light-6;
        margin:0 15px;
        display:none;

        &.show-filter{display:block}
    }
    
}



.filter-control{

    background-color: $color-grey-light-6;
    margin:20px 15px 0;
    position:relative;
    min-height: 30px;
    line-height: 30px;
    text-transform: uppercase;
    font-size:$font-size-tertiary;
    padding:10px 0;
    cursor: pointer;
    display:none;  
    
    @include breakpoint(tablet) {
        display: block!important;
    }

    .nav__icon{
        width:22px; 
        height:22px;
        margin:0 10px;
        vertical-align:middle;
    }

}

.filter-control:before {
    position: absolute;
    content: "";
    border: solid $color-grey-dark-3;
    border-width: 0 1px 1px 0;
    display: inline-block;
    top:20px;
    right:20px;
    padding:4px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg); 
}

.filter-box-title{
    font-size:$font-size-tertiary; 
    text-transform: none;
    margin: 0;
    float: left;
    width:100%

}

.filter-box{
    clear: both;
}

//.filter-box div.collapse[aria-expanded="false"] {
//    display:block;
//    overflow:hidden;    
//}

.filter-box .button{
    margin-bottom:15px;
    background-color: $color-white;
    display: inline-block;
}

.filter-box .button[aria-expanded="true"] .more, 
.filter-box .button[aria-expanded="false"] .less{
    display:block
}

.filter-box .button[aria-expanded="true"] .less, 
.filter-box .button[aria-expanded="false"] .more{
    display:none
}

.clean-filter{
    display:block;
    padding:1.5em 30px;
    font-size:$font-size-primary
}

div.checkbox-card .form-group{	
    padding:0 0 20px 40px;
    position:relative;
    margin:0  
}
div.checkbox-card .form-group input{	

    display: block!important;
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    z-index: 10;
    opacity: 0;
}
div.checkbox-card .form-group:before{	
    position:absolute;
    top:0; 
    left:0;
    content:"";
    border: 1px solid $color-grey-light-2;
    width:24px; 
    height:24px;
    display:block;
    z-index:1   

}
div.checkbox-card.selected .form-group:before{
    border: 3px solid $color-secondary;

}

div.checkbox-card.selected .form-group:after{
width: 9px;
height: 15px;
border: 3px solid $color-secondary;
border-left: none;
border-top: none;
content:" ";
position: absolute;
left: 8px;
top:2px;
-webkit-transform: rotatez(45deg);
        transform: rotatez(45deg);
cursor: pointer;
z-index: 3;

}

@include breakpoint(tablet) {

    //.filter .filter-item, 
    //.filter-box div.collapse[aria-expanded="false"]{
    //    display: -webkit-box;
    //    display: -ms-flexbox;
    //    display: flex;
    //    height:auto!important;
    //    overflow:visible;
    //    -webkit-box-orient: horizontal;
    //    -webkit-box-direction: normal;
    //        -ms-flex-flow: row wrap;
    //            flex-flow: row wrap;
    //}

    .filter .filter-item > .filter-level{
        width: 30%;
        margin-right: 1%;
        padding-left:0!important
    }
    div.filter-box .button{
        display:none!important
    }
    .filter .serviceboxed{
        border-left:none;
        border-right:none;
        border-top:none;
    }

}

.button--secondary-selected {
    -webkit-box-shadow: inset 20em 0 0 0 $color-secondary;
    box-shadow: inset 20em 0 0 0 $color-secondary;
    color: $color-white;
}

c0{ 
    font-weight: 900

}
.button--secondary:hover,
.button--secondary:focus {
    -webkit-box-shadow: inset 20em 0 0 0 $color-secondary;
            box-shadow: inset 20em 0 0 0 $color-secondary;
}

.filter-level{
    display:block
}

.filter .serviceboxed{
    display:block;    
    margin-top:1.4rem;
    padding:2.5rem 3rem 1.25rem 3rem;
    margin: 2rem 0;
}

.cat-btn .btn{
    font-size:13px;
    display: inline-block
}



.control-filter{

    .control-filter-item{
    
        background-color: $color-grey-light-5;
        color:$color-grey-dark-1;
        display:inline-block;
        padding:4px 8px;
        margin-right:10px;
        font-size: $font-size-quinary;
        cursor: pointer;
        margin-bottom:5px;
        
        .nav__icon{
            width:12px;
            height:12px;
            padding:0;
            margin-left:10px;
            vertical-align:middle;    
        }
    }
}

@include breakpoint(phone) {
    .filter-level{
        width: 100%!important
    }

}


.btn__filter--apply {
    background-color: $color-secondary;
    color: $color-white;
    padding: 10px 12px;
    margin:1rem 3rem 0;
    text-transform: uppercase;
    border: 0;
    border-radius: 0;
    
    &::after {
        background: url('data:image/svg+xml;utf8,<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 22.36 14.23"><defs><style>.cls-1{fill:white}</style></defs><path class="cls-1" d="M19.55 7.71H2.21a.6.6 0 0 1-.6-.6.59.59 0 0 1 .6-.59h17.34a.59.59 0 0 1 .6.59.6.6 0 0 1-.6.6z"/><path class="cls-1" d="M13.45 13.66a.6.6 0 0 1-.39-1l6.19-5.51-6.19-5.5a.6.6 0 1 1 .79-.89l6.69 6a.57.57 0 0 1 .2.44.59.59 0 0 1-.2.45l-6.69 6a.62.62 0 0 1-.4.01z"/></svg>')  no-repeat;
        height: 11px;
        width: 17px;
        display: inline-block;
        content: " ";
        margin-left: 7px; 
    }

    &:focus,
    &:hover {
        opacity: .9 !important;
        color: $color-white !important;
        background-color: $color-secondary !important;
        border-color: initial !important;
    }
}

.btn-now:focus, 
.btn-now:hover{
    opacity: .9 !important;
    color: $color-white !important;
    background-color: $color-secondary !important;
    border-color: initial !important;

}

.popover-content {
    width: 100%;
}
