

// center vertically and/or horizontally an absolute positioned element

@mixin center($xy:xy) {
  @if $xy == xy {
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    @include transform(translateX(-50%) translateY(-50%));
  }
  @else if $xy == x {
    left: 50%;
    right: auto;
    @include transform(translateX(-50%));
  }
  @else if $xy == y {
    top: 50%;
    bottom: auto;
    @include transform(translateY(-50%));
  }
}

// antialiasing mode font rendering

@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


// breakpoints
   
$S:     480px;   
$M:     768px;     
$L:     992px;     

// media queries

@mixin MQ($canvas) {
  @if $canvas == S {
   @media only screen and (min-width: $S) { @content; } 
  }
  @else if $canvas == M {
   @media only screen and (min-width: $M) { @content; } 
  }
  @else if $canvas == L {
   @media only screen and (min-width: $L) { @content; } 
  }
}

// super light grid - it works with the .cd-container class inside style.scss

@mixin column($percentage, $float-direction:left) {
  width: 100% * $percentage;
  float: $float-direction;
}

@mixin size($width, $eight) {
  width: $width;
  height:$height;
}
/* -------------------------------- 

Main Components 

-------------------------------- */


.navigation__background.navigation__background--full {
  top: $header-height;
  left: 0;
}

.cd-secondary-dropdown {
  > li > a {
    color: $color-grey-dark-3;
    font-size: $font-size-primary;
    pointer-events: auto;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0;

    &::after,
    &::before {
      /* hide the arrow */
      display: none;
    }

    svg {
      height: 12px;
      width: auto;
      color: inherit;
    }
  }
}
.cd-dropdown-trigger {
  display: block;
  position: relative;
  //   padding: 0 36px 0 20px;
  line-height: 40px;
  background-color: transparent;

  // .no-touch &:hover {
  //   // background-color: lighten($color-1, 5%);
  // }

  @include MQ(L) {
    font-size: $font-size-tertiary;

    &.dropdown-is-active {
      background-color: transparent;

      span {
        background-color: transparent;
      }
      .no-touch &:hover {
        background-color: transparent;
      }
    }
  }
}

.cd-dropdown h2,
.cd-dropdown-content a,
.cd-dropdown-content ul a {
  height: 50px;
  line-height: 50px;
  
  @include MQ(M) {
    height: 60px;
    line-height: 60px;
  }

  @include MQ(L) {
    height: 50px;
    line-height: 50px;
  }
}

.cd-dropdown h2,
.cd-dropdown-content a,
.cd-dropdown-content ul a,
.cd-dropdown-content .cd-divider {
  padding: 0 1rem 0 5rem;
}

.cd-dropdown {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-grey-light-0;
  color: $color-grey-dark-3;
  visibility: hidden;

  

  h2 {
    /* dropdown title - mobile version only */
    position: relative;
    z-index: 1;
    
  }

  .cd-close {
    /* 'X' close icon - mobile version only */
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    height: 50px;
    width: 50px;

    /* image replacement */
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    color: $color-grey-dark-3;

    &::after,
    &::before {
      /* this is the 'X' icon */
      content: "";
      position: absolute;
      height: 20px;
      width: 2px;
      background-color: $color-white;
    }

    &::after {
    }

    &::before {
    }
  }

  &-header {
    height: 50px;
    line-height: 50px;
    background-color: $color-grey-dark-3;
    position: relative;
    z-index: 1500;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding-left: 5rem;        

    &__container {
      padding: 0.5rem;
     

      @include breakpoint(tablet) {
        padding: 0;
        width: 90%;
      }

      @include breakpoint(smallest-phone) {

        width: 99%;
      }

      a {
        padding-right: 2rem;
      }
    }
  }

  &.dropdown-is-active {
    visibility: visible;
    z-index: 20000;
  }

  @include MQ(L) {
    position: fixed;
    top: $header-height;
    /* reset style*/
    height: calc(100vh - #{$header-height});
    width: 100%;
    overflow-y: auto;
    background-color: $color-grey-light-0;
    // box-shadow: 0 4px 12px rgba(#000, .1);
    opacity: 0;

    .open-to-left & {
      /* use the .open-to-left class if you want to open the dropdown on the left */
      right: 0;
      left: auto;
    }

    h2,
    .cd-close {
      /* on desktop - hide title and 'X' */
      display: none;
    }

    &.dropdown-is-active {
      opacity: 1;
z-index: 20000;
    }
  }
}

.cd-dropdown__dockbar {

  top: 48px;
  @include MQ(M) { top: 143px; }
  @include MQ(L) {
    top: 243px !important;
    /* reset style*/
    height: calc(100vh - 243px) !important;
  }
}


.cd-dropdown-content li.has-children {
 
  position: static;

    @include MQ(L) {
      position: relative;
    }
}

.cd-dropdown-content,
.cd-dropdown-content ul {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 50px;
  margin-bottom: 0;
  padding-left: 0;
  background: $color-grey-light-0;

  li {
    &:hover > a {
      color: $color-grey-light-3;
      text-decoration: none;
      font-weight: 600;
      background-color: $color-grey-dark-3;
       

    }
  }

  a {
    display: block;
    color: $color-grey-dark-3;
    font-weight: 400;
    /* truncate text with ellipsis if too long */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: none;
    display: flex;
    align-items: center;

    &:hover {
      
    }
  }

  .cd-divider + li > a {
    border-top-width: 0;
  }

  a,
  .cd-search,
  .cd-divider {
    /* Force Hardware Acceleration */
  }

  &.is-hidden {
    /* push the secondary dropdown items to the right */
  }

  &.is-hidden > li > a,
  &.is-hidden > li > .cd-search,
  &.is-hidden > .cd-divider,
  &.move-out > li > a,
  &.move-out > li > .cd-search,
  &.move-out > .cd-divider {
    /* lower down the dropdown items opacity - when secondary dropdown slides in or for dropdown items hidden on the right */
    opacity: 0;
  }

  &.move-out > li > a,
  &.move-out > li > .cd-search,
  &.move-out > .cd-divider {
    /* push the dropdown items to the left when secondary dropdown slides in */
  }

  .dropdown-is-active & {
    -webkit-overflow-scrolling: touch;
  }

  @include MQ(M) {
    padding-top: 60px;

    a {
      font-size: 1.8rem;
    }
  }

  @include MQ(L) {
    padding-top: 0;
    overflow: visible;

    a {
      height: 50px;
      line-height: 50px;
      font-size: 1.5rem;
      cursor: pointer;
    }

    .cd-divider {
      background-color: transparent;
    }

    .cd-divider + li > a {
      border-top-width: 1px;
      cursor: pointer;
    }

    &.is-hidden > li > a,
    &.is-hidden > li > .cd-search,
    &.is-hidden > .cd-divider,
    &.move-out > li > a,
    &.move-out > li > .cd-search,
    &.move-out > .cd-divider {
      /* reset mobile style */
      opacity: 1;
    }
  }
}

.cd-dropdown-content {
  padding: 0 0 0 8%;
  margin-top: 50px;
  border-right: 1px solid $color-grey-light-5;

  @include breakpoint(tablet) {
    margin-top: 70px;
    padding: 0 30% 0 30%;
    height: calc(100vh - 70px);
  }

  @include breakpoint(phone) {
    padding: 0 15%;
  }

  .see-all a {
    /* different style for the See all links */
  }

  .cd-dropdown-gallery .cd-dropdown-item,
  .cd-dropdown-icons .cd-dropdown-item {
    /* items with picture (or icon) and title */
    height: 80px;
    line-height: 80px;

    h3 {
      /* truncate text with ellipsis if too long */
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .cd-dropdown-icons .cd-dropdown-item {
    padding-left: 75px;
    position: relative;

    p {
      font-size: 1.3rem;
      /* hide description on small devices */
      display: none;
    }

    &::before {
      /* item icon */
      content: "";
      display: block;
      position: absolute;
      left: 20px;
      top: 50%;
      margin-top: -20px;
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 40px 40px;
    }
  }

  @include MQ(L) {
    /* reset mobile style */
    position: static;
    height: auto;
    width: 430px;

    > li:last-of-type a {
      border-bottom: none;
    }

    .no-touch & > li a:hover {
      color: $color-grey-dark-3;

      
        background-color: $color-grey-light-3;
    

      text-decoration: none;
      font-weight: 600;
    }

    &.move-out > li > a,
    &.move-out > li > .cd-search,
    &.move-out > .cd-divider {
      /* reset mobile style */
      opacity: 1;
    }

    .cd-secondary-dropdown,
    .cd-dropdown-gallery,
    .cd-dropdown-icons {
      left: 100%;
      height: auto;
      background-color: $color-grey-light-0;
      // box-shadow: 0 2px 14px rgba(#000, .1);

      .open-to-left & {
        /* use the .open-to-left class if you want to open the dropdown on the left */
        left: auto;
        right: 100%;
      }

      &.is-hidden {
        /* reset mobile style */
      }

      &.fade-in {
        /* animate secondary dropdown items when hovering over of of the .cd-dropdown-content list items */
      }

      &.fade-out {
        /* animate secondary dropdown items when hovering over of of the .cd-dropdown-content list items */
      }

      > .go-back {
        display: none;
      }

      > .see-all {
        position: absolute;
        bottom: 20px;
        height: 45px;
        text-align: center;

        a {
          margin: 0;
          height: 100%;
          line-height: 45px;

         background: $color-grey-dark-3;

          pointer-events: auto;

          .no-touch &:hover {
            color: yellow;
          }
        }
      }

      .cd-dropdown-item,
      a {
        border: none;
      }
    }

    .cd-secondary-dropdown {
      left:101%;

      //min-height: 170px;
      padding:0 0 0 30px!important;
    }

    .cd-dropdown-gallery,
    .cd-dropdown-icons {
      padding: 20px 30px 100px;

      > .see-all {
        width: calc(100% - 60px);
      }
    }

    .cd-dropdown-icons > li,
    .cd-secondary-dropdown > li {
    }

    .cd-secondary-dropdown {
      overflow: hidden;
      width: 250px;
      padding: 0;
      height: 100%;
      padding: 15% 100px;

      > li > a {
        color: $color-grey-dark-3;
        font-size: $font-size-primary;
        pointer-events: auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        cursor: pointer;
       

        &::after,
        &::before {
          /* hide the arrow */
          display: none;
        }

        svg {
          height: 12px;
          width: auto;
          color: inherit;
        }
      }

      &.move-out > li > a {
        /* reset mobile style */
      }

      > li {
        // margin: 20px 0;

        // padding-bottom: 30px;
        // border-right-width: 1px;
        // border-color: darken($color-3, 8%);
        // border-style: solid;
        // padding: 0 30px;
        float: left;
        width: 100%;
        height: auto;
        cursor: pointer;

        &:hover {
          > a {
           color: $color-grey-dark-3;

            font-weight: 600;

            > svg {
              height: 13px;
            }
          }
        }
      }

      > li:nth-of-type(2n) {
        border-right-width: 0;
      }

      > li > ul {
        /* reset mobile style */
        position: relative;
        height: auto;

        > .go-back {
          display: none;
        }
      }

      a {
        color: $color-grey-dark-3;
        font-size: $font-size-primary;
        padding: 20px;
        line-height: 10px;

        .no-touch &:hover {
         color: $color-grey-dark-3;

         background: $color-grey-light-3;
          
        }
      }

      ul {
        padding-bottom: 25px;
        overflow: hidden;
        height: auto;
      }

      .go-back a {
        padding-left: 20px;
        color: transparent;

        .no-touch &:hover {
          color: transparent;
        }

        &::before,
        &::after {
          left: 0;
          background-color: #333;
        }
      }

      .see-all {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }

    .cd-dropdown-icons {
      width: 600px;

      .cd-dropdown-item {
        height: 80px;
        line-height: 1.2;
        padding: 24px 0 0 85px;

        &:hover {
          background: purple;
        }

        h3 {
          font-weight: bold;
        }

        p {
          display: block;
          font-size: 1.2rem;
        }

        &::before {
          left: 25px;
        }
      }
    }

    > .has-children > ul {
      visibility: hidden;
    }

    > .has-children > ul.is-active {
      /* when hover over .cd-dropdown-content items - show subnavigation */
      visibility: visible;
    }

    > .has-children > .cd-secondary-dropdown.is-active > li > ul {
      /* if .cd-secondary-dropdown is visible - show also subnavigation */
      visibility: visible;
    }

    > .has-children > a.is-active {
      /* hover effect for .cd-dropdown-content items with subnavigation */ 
              
        color: $color-grey-dark-3;
    
        background-color: $color-grey-light-3;
       
  
      &::before,
      &::after {
        background: $color-grey-light-3;
      }
    }

    .open-to-left & > .has-children > a.is-active {
      -webkit-box-shadow: inset -2px 0 0 green;
              box-shadow: inset -2px 0 0 green;
    }
  }
}

.cd-dropdown-content__responsive {
  &.cd-dropdown-content {
    @include breakpoint(tablet) {
      height: calc(100vh - 150px);
    }
    @include breakpoint(phone) {
      height: calc(100vh - 210px);
    }
  }

  @include breakpoint(tablet) {
    margin-top: 130px;
  }

  @include breakpoint(phone) {
    margin-top: 180px;
  }
}


.has-children > a,
.go-back a {
  position: relative;

  &::before,
  &::after {
    /* arrow icon in CSS - for element with nested unordered lists */
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -1px;
    display: inline-block;
    height: 1px;
    width: 9px;
    background: $color-grey-dark-3!important;
  }

  &::before {
  }

  &::after {
  }

  @include MQ(L) {
    &::before,
    &::after {
      background: $color-grey-dark-3;
    }
  }
}

.has-children > a {
  padding-right: 40px;

  &::before,
  &::after {
    /* arrow goes on the right side - children navigation */
    right: 20px;
  }
}

@include MQ(L) {
  .open-to-left .cd-dropdown-content > .has-children > a {
    // padding-left: 40px;
    padding-right: 20px;

    &::before,
    &::after {
      right: auto;
      left: 20px;
    }
  }
}

.cd-dropdown-content .go-back a {
  
  &::before,
  &::after {
    /* arrow goes on the left side - go back button */
    left: 20px;
  }
}

.cd-main-content {
  min-height: calc(100vh - 100px);
  padding: 2em 5%;
  line-height: 2;
}

.no-js .cd-dropdown-wrapper:hover .cd-dropdown {
  visibility: visible;
  opacity: 1;
}

.no-js .cd-dropdown-wrapper .cd-close {
  display: none;
}

.navigation {
  //BACKGROUND

  &__background {
    display: none;
    height: 4rem;
    width: 4rem;
    top: 84px;
    left: -4rem;
    position: absolute;
    background-color: $color-grey-light-0;
    z-index: 2001;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    -webkit-transform-origin: top;
            transform-origin: top;
  }

  &__background--full {
    display: block;
    height: calc(100vh - #{$header-height});
    width: 100vw;
  }

  &__icon {
    position: relative;
    margin-top: 3.5rem;

    &,
    &::before,
    &::after {
      width: 3rem;
      height: 2px;
      background-color: grey;
      display: inline-block;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      -webkit-transition: all 0.2s;
      transition: all 0.2s;
    }

    &::before {
      top: -0.8rem;
    }
    &::after {
      top: 0.8rem;
    }
  }
}

///burger icon

.menu-toggle {
  position: relative;
  display: block;
  width: 33px;
  height: 23px;
  background: transparent;
  border-top: 2px solid;
  border-bottom: 2px solid;
  color: $color-grey-dark-3 !important;
  font-size: 0;

  -webkit-transition: all 0.25s ease-in-out;

  transition: all 0.25s ease-in-out;

  &:before,
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    top: 50%;
    left: 50%;

    background: currentColor;

    -webkit-transform: translate(-50%, -50%);

            transform: translate(-50%, -50%);
    -webkit-transition: -webkit-transform 0.25s ease-in-out;
    transition: -webkit-transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out;
    transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
  }
  @include breakpoint(phone) {
    width: 22px;
    height: 16px;
  }
}

.menu-toggle:hover {
  color: lighten($color-grey-dark-3, 15%) !important;
}

.menu-toggle.is-active {
  border-color: transparent;

  &:before {
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
            transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
            transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.cd-secondary-dropdown {

  li {
    @include breakpoint(tablet) {
      padding: 0 20%;
    }
  }

  a {
    padding: 0 20%;
    cursor: pointer;
  }

  .go-back a {
    position: relative;
    margin-bottom: 2rem !important;

    &::before,
    &::after {
      /* arrow icon in CSS - for element with nested unordered lists */
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -1px;
      display: inline-block;
      height: 1px;
      width: 9px;
      background: $color-grey-dark-3!important;
    }

    &::before {
    }

    &::after {
    }

    @include MQ(L) {
      &::before,
      &::after {
        background: $color-grey-dark-3;
      }
    }
  }

}

.menu-opened{
  overflow:hidden!important//retira o scroll da página se o menu está activo
}

.menu-nav__language {
  text-transform: uppercase;
  border: none;
  cursor: pointer;
}


//Título em cima do burger icon

.cd-dropdown-wrapper {
  position: relative;
}

.cd-dropdown-title {
    position: absolute;
    top: -24px;
    left: 0;
    font-size: 11px;
    @include breakpoint(tablet) {
      display: none;
    }
}

.cd-secondary-dropdown {
  @media all and (min-width: 991px) { 
    width:auto!important;
    height:100% !important;
    overflow: visible!important;

    > li {
      overflow: visible;
    }
    > li a {
      overflow: visible;
      text-overflow:clip;
      display:block!important;
    }
    > li > a svg{
      margin-left:10px
    }
  }
  > li {
    @include breakpoint(phone) {
      padding: 0 5% !important;
    }
  }
}