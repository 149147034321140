/* --------------------------------

Icon colors

-------------------------------- */

.nc-icon {
    display: inline-block;
    
    height: 66px;
    width: 66px;  
  }
  
  .nc-icon-white {
    color: $color-white;
  }
  
  .nc-icon use {
    /* change 'inherit' to a color value if you want to add a secondary color to Nucleo icons */
    fill: inherit;
  }
  
  .nc-icon.nc-icon-outline use {
    /* change 'inherit' to a color value if you want to add a secondary color to Nucleo icons */
    stroke: inherit;
  }
  
  /* --------------------------------
  
  Icon basic style
  
  -------------------------------- */
  .nc-icon.nc-icon-grid-13 {
    height: 13px;
    width: 13px;
  }

  .nc-icon.nc-icon-grid-14 {
    height: 14px;
    width: 14px;
  }
  
  .nc-icon.nc-icon-grid-16 {
    height: 16px;
    width: 16px;
  }
  
  .nc-icon.nc-icon-grid-20 {
    height: 20px;
    width: 20px;
  }
  
  .nc-icon.nc-icon-grid-24 {
    height: 24px;
    width: 24px;
  }

  .nc-icon.nc-icon-grid-28 {
    height: 28px;
    width: 28px;
  }
  
  .nc-icon.nc-icon-grid-32 {
    height: 32px;
    width: 32px;
  }
  .nc-icon.nc-icon-grid-36 {
    height:36px;
    width:36px;
  }
  .nc-icon.nc-icon-grid-48 {
    height: 48px;
    width: 48px;
  }
  
  .nc-icon.nc-icon-grid-64 {
    height: 64px;
    width: 64px;
  }
  
  .nc-align-to-text {
    /* scale and align icon to the text element */
    display: inline-block;
    width: 1em;
    height: 1em;
    vertical-align: middle;
    position: relative;
    top: -0.0625em;
    stroke: none;
    fill: none;
  }
  
  .nc-align-to-text use {
    color: inherit;
  }
  
  .nc-icon,
  .nc-align-to-text  {
    fill: currentColor;
    stroke: none;
  }
  
  .nc-icon.nc-icon-outline,
  .nc-align-to-text.nc-icon-outline  {
    fill: none;
    stroke: currentColor;
  }
  
  .nc-icon use {
    stroke: none;
  }
  
  .nc-icon.nc-icon-outline use {
    fill: none;
  }
  
  .nc-icon.nc-icon-outline.stroke-1,
  .nc-align-to-text.nc-icon-outline.stroke-1 {
    stroke-width: 1px;
  }
  
  .nc-icon.nc-icon-outline.stroke-2,
  .nc-align-to-text.nc-icon-outline.stroke-2 {
    stroke-width: 2px;
  }
  
  .nc-icon.nc-icon-outline.stroke-3,
  .nc-align-to-text.nc-icon-outline.stroke-3 {
    stroke-width: 3px;
  }
  
  .nc-icon.nc-icon-outline.stroke-4,
  .nc-align-to-text.nc-icon-outline.stroke-4 {
    stroke-width: 4px;
  }
  
  .nc-icon.nc-icon-outline.stroke-1 use,
  .nc-icon.nc-icon-outline.stroke-3 use,
  .nc-align-to-text.nc-icon-outline.stroke-1 use,
  .nc-align-to-text.nc-icon-outline.stroke-3 use {
    -webkit-transform: translateX(0.5px) translateY(0.5px);
    -moz-transform: translateX(0.5px) translateY(0.5px);
    -ms-transform: translateX(0.5px) translateY(0.5px);
    -o-transform: translateX(0.5px) translateY(0.5px);
    transform: translateX(0.5px) translateY(0.5px);
  }
  
  // .cls-1 {
  //   fill:none;
  //   stroke:#333;
  //   stroke-linecap:round;
  //   stroke-linejoin:round;
  //   stroke-width:3px;
  // }
  
  .serviceboxedOG nc-icon {
    flex-shrink: 0 !important;    
  }
  
