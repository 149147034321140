@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import '~bootstrap/dist/css/bootstrap.min.css';
@import './variables';
@import './mixins';
@import './main';

button{
    cursor:pointer
}

// //esta classe pertence ao css do portal preprod.eportugal.gov.pt mas n pertence ao css que nos foi disponibilizado no git -.-
// #imgLogoHeader {
//     width: 200px;
//     height: auto;
// }

html, body{
    font-family: "Noto Sans", sans-serif;
  }

.dropdown-toggle::after{
border:none;
}

.search-top .form-inline{
    display:inline-flex;
}

@media (max-width: 767px){
    .navbar{
        z-index:2300;
    }

    .pl-1{
        padding-left:0!important
    }
}

.footer_row{
    margin-left:0!important;
    margin-right:0!important;
}


@media all and (max-width: 767px) {
    #section-header .drop-contactos .btn-contactos-mobile {
      padding: 1.6rem; }
    #section-header .drop-contactos .btn-icon-contactosmobile,
    #section-header .drop-contactos .btn-icon-close,
    #section-header .drop-contactos .btn-icon-profile {
        padding: 1.1em 1.15em 1em !important;
        height: 57px
    }
}
