// TOOLTIP 

.tooltip{

    z-index: 25000 ;

    &.in{opacity:1!important;}
    .tooltip-arrow{
        border-bottom-color:$color-white!important;
        border-top-color:$color-white!important;
        opacity: 1 !important; 
    }

    .tooltip-inner{
        border-radius: 0;
        background-color:rgba($color-white,1);
        box-shadow: $box-shadow-primary;
        font-size:$font-size-primary;
        font-weight:300;
        color:$color-grey-dark-3;
        padding:10px 15px;
        font-family: "Noto Sans", sans-serif;
        opacity: 1 !important; 
        size: 12px;
        line-height: 16px;
    }
}


.portlet__form {

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin: 0 auto;

    input {
      border-radius: 0;
      border: 0;
      border-bottom: 1px solid $color-black;
    }
  
    .lexicon-icon {
      display: block;
      height: 35px;
      line-height: 35px; 
      color: $color-secondary;
      width: 35px;
      margin-left: -35px;
      margin-top: -5px;
    }

    &--search {
        // margin-top: -19px;
    }

    .form-group-inline {
         margin:0;
    }

    .lfr-ddm-field-group {
        margin:0;
    }
  }

.breadcrumb-horizontal {
    background-color: $color-secondary;
    margin-bottom: 0;
    border-bottom-left-radius:0;
    border-bottom-right-radius:0;
    border-top-left-radius:0;
    border-top-right-radius:0;
    padding: 2rem 4.5rem 0 4.5rem;
    font-size: $font-size-secondary;
    a {
        color:$color-white;

        &:hover,
        &:focus {
            color: $color-white;
        }
    }
    li.active {
        color:$color-white;
    }
    ul li::before {
        content: "|";
        color: $color-white;
        background-image: none;
        position: absolute;
        left: 0;
        width: 15px;
        height: 15px;
        line-height: 1;
        z-index: 1;
    }

    @include breakpoint(tablet) {
       display: none;
      }
}

.sidenav-fixed { z-index: 4000 !important;} 


//PADDING-TOP NO BODY QUANDO DASHBOARD ABRE

.body-dashboard__padding {
    padding-top: 171px;
    -webkit-transition: padding-top .3s; /* Safari */
    transition: padding-top .3s;

    @include breakpoint(tablet) {
        padding-top: 163px;
      }
    @include breakpoint(phone) {
        padding-top: 210px;
      }
}

.dockbar__margin {
    z-index: 1000 !important;
    background-color: $color-white;

    @include breakpoint(phone) {
       // margin-top:48px;
      }
}

.body-dockbar__padding {
    padding-top:65px!important;
    margin-top:0!important;

    #main-content{
        padding-top:40px;

        #homepage-section .homepage__first-row {
            overflow: visible        
        }
    }
}

/*n apagar

.dialog-iframe-modal {
    z-index: 5000 !important;
}

.lfr-tag-selector-popup {
    z-index: 5001 !important;
} */


/*.modal {
    z-index: 5000 !important;
}

.modal-focused {
    z-index: 5001 !important; 
}*/

/*.yui3-widget-content-expanded {
    z-index: 5001 !important; 
} */

#btnTop {
    display: none; 
    -webkit-box-align: center; 
        -ms-flex-align: center; 
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: absolute;
    bottom: 170px;
    right: 60px;
    z-index: 99; 
    border: none; 
    outline: none; 
    background-color: $color-primary;
    color: $color-grey-dark-3;
    cursor: pointer;
    height: 40px;
    width: 40px;

    &:hover,
    &:focus {
        background-color:darken( $color-primary, 10%);
    }

    @include breakpoint(phone) {
        bottom: 170px;
        right: 60px;
      }

    svg {
        color: $color-white;
        height: 20px;
        width: auto;
        -webkit-transform: rotateZ(-90deg);
                transform: rotateZ(-90deg)
    }
}

#blockbyte-bs-sidebar {
    display: none;
}

.user-list-data{
    ul{
        padding-left: 0;
    }
    .panel__icon { 
        margin-right: 15px;
    }
}


/////////FixPack 48 FIX
.autofit-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.autofit-col-expand {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
} 
/////////////////////////

// SKIP LINKS

.skip-links  {
   
    margin: 0;
    padding: 0;
    a,button {
        position: absolute;
        top: -3em;
        left: 0;
        background: $color-secondary !important;
        color: $color-white !important;
        padding: 8px;
        border: none;
        z-index: 60000;
        text-decoration: none;

        &:focus {
            top: 0;
        }
    }

   
}

#modalInfo{

    @include breakpoint(tablet) {
        position:absolute
    }

    .modal-dialog {

        @include breakpoint(phone) {
            width:100%;
            top:0!important;
            position: fixed;
    
        }
        
    } 

    .modal-header{
        .btn-toolbar{
            display: flex;
            margin-top: -18px;
        }
    }
    
    .main-header{
        display: flex;
    }
    
    .modal-content{
        box-shadow:none;
        border-radius:0;
        border:none;
        padding: 18px;
    }
    
    .modal-body{
        padding-left: 50px;
    }
    
    .modal-header, 
    .modal-body, 
    .modal-footer{
        max-width: 990px;
        margin: 0 auto;
        border:none
    }
    .modal-footer {
        text-align: right;
    }
    .modal-footer .btn{
        float:none;
    }
    .close{
        display:none;
    }
}

.error{
    color: $color-red;
}
.info{
    color: $color-secondary;
}
.warn{
    color: $color-primary;
}
.success{
    color: $color-green;
}

.btn-chat, .btn-contactos{
    padding: 0.4rem 1.5rem !important;
}


.format-p p{display:block; width: 100%}
