.floatDIv--click2call{
    width: 380px!important;
    background-color: $color-white; 
    max-height:560px!important;    
    bottom: 0;

    @include breakpoint(phone) {
        width:100%!important;
        max-height:100%!important; 
    }
}

.floatDIv--click2call .floatDiv__container{
    overflow: hidden;
    position: absolute;
    right: 0;
    left: 0;
    top: 55px;
    bottom: 0;
    width: 100%;
}

.contact-form{
    padding:20px 5px;
    bottom:0;
    transform: translateY(0);
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    right: 0;
    top: 0px;
    width: 100%;

    fieldset{
        margin:0
    }
    legend{
        padding:0 15px;
        font-size: $font-size-primary;
        margin-bottom:0;
    }
    .intro-form{
        padding-bottom:15px; 
        margin:0 15px 15px;
        border-bottom: 1px solid $color-grey-light-5;
    }
    .contactFormTo{
        display:inline-block;
        width: 49%
    }
    .radio label{
        padding-left:0;
    }

    input[type=radio]:checked + label::after {
        left: 6px;
        bottom: 6px;
    }
    .selected{
        -webkit-box-shadow: inset 20em 0 0 0 $color-secondary;
                box-shadow: inset 20em 0 0 0 $color-secondary;
        color:$color-white;
    }
    .btn{
        width: 100%
    }
    .custom-label{
        font-weight:400;
        color:$color-grey-light-9;
        font-size:$font-size-primary
    }
    .label--block{
        font-weight: 400;
    }
    .mandatory-info{
        font-size: $font-size-quinary;
        color:$color-grey-dark-2;
        display: block;
        margin-left:15px
    }
    .select-template{
        display:inline-block;
        &:before{
            z-index: -1;
        }
    }
    select{
        background-color:transparent;
        border:none;
        color:$color-grey-light-9;
        padding-left: 0;
        padding-right:25px;
        &:focus{
            background-color: transparent;
        }
    }
    .click2callForm_intent_label{margin: 0!important}
    
}

.click2callForm_intent_Selected[aria-required="false"]{
    display:none
}

 
.contact-error, 
.contact-success{
    text-align: center;
    padding:0 15px;
    display:none;

    .icon-success,
    .icon-error{
        width:50px;
        height:50px;
        margin:30px auto
    }
    .sub-header{
        font-weight:900
    }

    .icon-success{
       color:$color-green
    } 
    .contact-after-control{
        margin:30px 0;
        padding-top:40px;
        border-top: 1px solid $color-grey-light-5;
    }
    .icon-error{
        color:$color-red;
        width:40px;
        height:40px;
    }
    .fechar-msg{
        color:$color-secondary;

        .nc-icon{
            width:20px;
            height:20px;
            margin:0 0 0 1.5rem;
        }
    }
}

.more-info{
    font-size: 12px;
    line-height: 18px;
    margin-bottom:0
}
#sendContact svg{
    height: 22px;
    width: 22px;
    margin-right: 10px;
    flex-shrink: 0;
    vertical-align: middle;
}
.grecaptcha-badge {
	display: none;
}

