// COMPONENTS

.col__block {
  height: 101px;
  background-color: $color-grey-light-1;

  @include breakpoint(tablet) {
    background-color: $color-white;
  }

  @include breakpoint(phone) {
    display: none;
  }
}

// .input__search {
//   padding-left: 0;
//   font-size: 12px;
//   font-weight: 300;
//   width: 90% !important;
//   border: none;
//   border-radius: 0;
//   border-bottom: 1px solid #000;


//   &-icon {
//     height: 25px;
//     width: 25px;
//     padding: 0;
//     background-color: inherit;
//     border: none;
//   }
// }

//   CARDS

.card__inner-border--top {
  border-top: 1px solid $color-grey-light-2;
}

.card__inner-border--left {
  border-left: 1px solid $color-grey-light-2;
}

// BUTTONS


.button {
  padding: 1rem 1.5rem;
  text-align: center;
  text-transform: uppercase;
  background: transparent;
  font-size: $font-size-primary;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  -webkit-transition: .5s;
  transition: .5s;
}

.button--dark {
  border: 1px solid $color-grey-dark-3;
  color: $color-grey-dark-3;
}

.button--white {
  border: 1px solid $color-white;
  color: $color-white;
}

.button--primary {
  border: 1px solid $color-primary;
  color: $color-primary;
}

.button--secondary {
  border: 1px solid $color-secondary;
  color: $color-secondary;
  height: 39px;
  line-height: 1px;
  > svg{
  height: 22px;
  width: 22px;
  }

  &:hover, &:focus {
    -webkit-box-shadow: inset 9em 0 0 0 $color-secondary;
            box-shadow: inset 9em 0 0 0 $color-secondary;
    color: $color-white;

    > svg{color:$color-white;stroke: $color-white;}

    a {
      color: $color-white;
    }
  }
}



.btn-bg-primary {
  background-image: -webkit-gradient(
    linear,
    left bottom, left top,
    from($color-primary),
    color-stop(50%, $color-primary),
    color-stop(51%, $color-primary-dark)
  );
  background-image: linear-gradient(
    to top,
    $color-primary 0%,
    $color-primary 50%,
    $color-primary-dark 51%
  );
}

.btn-bg-secondary {
  background-image: -webkit-gradient(
    linear,
    left bottom, left top,
    from($color-secondary),
    color-stop(50%, $color-secondary),
    color-stop(51%, $color-secondary-dark)
  );
  background-image: linear-gradient(
    to top,
    $color-secondary 0%,
    $color-secondary 50%,
    $color-secondary-dark 51%
  );
}

// SELECT FORM

.select-field {
  display: inline-block;
  // width: 100%;
  max-width: 450px;
  margin: 0;
  padding: 0;
  background-color: $color-grey-light-1;
  border-radius: 0;
  margin-right: 2rem;
}

.link {
  color: $color-secondary;
  text-decoration: underline;
  font-size: $font-size-primary;
}

//Select Liferay
.select-liferay{

  label{
    display:inline-block;
    color:$color-grey-dark-1;
    width:50%;
    text-align:right;
    padding:8px 15px 0 0
  }
  select{
    width:50%;
    float:right;
    padding-left:20px;

    @include breakpoint(tablet) {
      padding-left:1rem;
    }
  }

  .form-group{
    position:relative;
  }

  .form-group:before{
    position:absolute;
    content:"";
    border: solid $color-grey-dark-3;
    border-width: 0 1px 1px 0;
    display: inline-block;
    top:12px;
    right:12px;
    padding: 3px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    }
}

.select-template{

  position: relative;

  select{
    background-color: transparent;
    color:$color-grey-dark-3;
    padding:5px 20px 5px 5px;
    border-bottom: 1px solid $color-black;
    font-size:$font-size-quaternary;
  }
  select:focus{
    background-color: $color-grey-light-6;
  }
}
.select-template:before{
  position:absolute;
  content:"";
  border: solid $color-grey-dark-3;
  border-width: 0 1px 1px 0;
  display: inline-block;
  top:10px;
  right:10px;
  padding: 3px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  }


  //Radio Liferay
.radio-liferay{

  label{
    display:inline-block;
    position:relative;
    padding-left:0;
    min-height:24px
  }
  input[type="radio"]{
    width: 24px;
    height: 24px;
    opacity: 0;
    display: block;
    top: 0;
    z-index: 9;
    margin:0
  }
 label:before {
    display: inline-block;
    width: 24px;
    height: 24px;
    content: " ";
    border: 1px solid #ccc;
    vertical-align: top;
    cursor: pointer;
    z-index: 10;
    border-radius: 50%;
    padding-left: 0;
    margin-right:15px
  }
  label:after{
    width: 12px;
    height:12px;
    border-radius: 50%;
    display:none;
    background-color: #034AD8;
    position:absolute;
    top:6px;
    left:6px;
    content:""

  }

}

.radio-liferay.selected label:after{
  display:block
}
.radio-liferay.selected label:before {
  border:2px solid #034AD8
}


// TABLE
.prettytable {
  table {
    border-collapse: separate;
    width: 100%;
  }

  th, td {
    text-align: center;
    padding: 8px;
  }

  th {
	  background-color: $color-secondary;
    color: $color-white;
  }

  tr:nth-child(even) {background-color: $color-grey-light-5;}
  tr:nth-child(odd) {background-color: $color-grey-light-1;}

}

//liferay table

.table {
  width: 100%;
  border:0;
  border-spacing:0;
  border-collapse: collapse;

  th, td{
    text-align: left;
    padding:20px!important;
    font-size:$font-size-quaternary;
  }

  th{
    background-color: $color-secondary;
    color: $color-white;
    text-transform: uppercase;
    font-weight: normal;
    border-color:$color-secondary!important;
  }

  td {
    border:none!important;
    border-top: 5px solid $color-white!important;
    color:$color-grey-dark-2;
  }

  tr:nth-child(even) {background-color: $color-grey-light-1}
  tr:nth-child(odd) {background-color: $color-grey-light-0}

  td .nav__icon{
    width:22px;
    height:22px;
  }
  td .nav__icon.delete-icon{
    color:$color-red
  }

}


//LISTS WITH ICONS AND NUMBERS FOR ORDERED LISTS

.checkedlist {
  ul li {
    list-style-image: url(#{$images-path}/icons/checked.svg);
  }

  ul { padding: 33 !important; }

  ol { padding: 30 !important; }

  ol li::before {
    color: $color-secondary;
  }
}


/////////////////HIDDEN/VISIBLE LINKS
.hiddenlinks-3 {
  ul li:nth-child(n+4) {
    display:none;
  }

  ol li:nth-child(n+4) {
    display:none;
  }

  ul { padding: 0 !important; }

  ol { padding: 0 !important; }
}

.hiddenlinks-5 {
  ul li:nth-child(n+6) {
    display:none;
  }

  ol li:nth-child(n+6) {
    display:none;
  }

  ul { padding: 0 !important; }

  ol { padding: 0 !important; }
}

.hiddenlinks-6 {
  ul li:nth-child(n+7) {
    display:none;
  }

  ul li:nth-child(n+7) {
    display:none;
  }

  ul { padding: 0 !important; }

  ol { padding: 0 !important; }
}

.visiblelinks {
  ul{
    padding: 0 !important;
    li {
      display: list-item;
    }
  }

  ol{
    padding: 0 !important;
    li {
      display: list-item;
    }
  }

  .service-list-mosaic{

      li {
        display:flex;
      }

  }
}

ul.sitemapLinksHidden {
  > li {
    display:none;
  }
}

ul.sitemapLinksVisible {
  > li {
    display:list-item;
  }
}
///////////////////////////////////


// Paginação Liferay

.search-results{
  display:none;

}

.lfr-pagination-config{

  .lfr-pagination-delta-selector{
    display:none!important;
  }
  a.dropdown-toggle{
    background-color: transparent;
    color:$color-grey-dark-3;
    padding: 5px 30px 5px 5px;
    border-bottom: 1px solid $color-black;
    font-size: 13px;
    text-transform: none;
  }
  a.dropdown-toggle:before{
    position:absolute;
    content:"";
    border: solid $color-grey-dark-3;
    border-width: 0 1px 1px 0;
    display: inline-block;
    top:10px;
    right:10px;
    padding: 3px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  a.dropdown-toggle,
  a.dropdown-toggle:hover,
  a.dropdown-toggle:active,
  a.dropdown-toggle:focus{
    background-color:transparent!important;
    box-shadow:none!important;
  }
  a.dropdown-toggle i{
    display:none;
  }
}

.lfr-menu-list{
  max-height:180px!important;
  width:80px!important;
  min-width:80px!important;

  @include breakpoint(phone) {
    width:50px!important;
    min-width:50px!important;
}
  .btn-toolbar.search-panel{
    display: none;
  }
}




.lfr-pagination{

  .pager  {
    padding-top:6px;

  }

  .pager li > a,
  .pager li > span {
    border: none!important;
    text-transform: uppercase;
  }
  .pager li > a:hover,
  .pager li > a:focus {
    background-color:transparent;
    text-decoration:underline;
  }

  .pager li > a{
    position:relative;
    color:$color-secondary;

    @include breakpoint(phone) {
      width: 100%;
    }
  }
  .pager .disabled > a{
    color:$color-grey-dark-2
  }

  .pager li > a:before{
    position:absolute;
    content:"";
    border: solid $color-secondary;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    top:13px;
  }

  .pager .disabled > a:before{
    border-color:$color-grey-dark-2
  }

  .pager li:nth-child(2) > a:before{

    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    left:0
  }
  .pager li:nth-child(3) > a:before{

    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    right:2px
  }

  .pager li:first-child,
  .pager li:last-child{
    display: none!important
  }
}



    @media (max-width: 767px) and (max-width: 979px) {

  .taglib-page-iterator .lfr-pagination-buttons {
      float: right;
      width: 99%;
  }
  .lfr-pagination .pager li > a:before {top: 17px}
}



// CALENDÁRIO // DATEPICKER ALLOYUI
  .calendar {
    display: inline-block;
    padding-bottom: 1rem;
    cursor: pointer;
    width: 20px;

    svg{
      vertical-align: middle;
    }
  }

  .calendar-container {
    span, .calendar__input { margin-right: 1rem; }

    @include breakpoint(tablet) {
      padding: 3rem;
    }
    @include breakpoint(phone) {
      padding: 2rem .5rem;
    }

    .calendar__input {
      display: inline-block;
      width: 4rem;

      &--year {
        width: 6rem;
        margin-right: 2rem;
        @include breakpoint(phone) {
          margin-right: 1rem;
        }
      }

    }

  }
  .popover > .arrow {display: none;}

.datepicker-popover{

  border-radius: 0;
  box-shadow: $box-shadow-primary;

    &-content {

      * {
        font-family: "Noto Sans", sans-serif !important;
        background-color: $color-grey-light-4;
      }



    .popover-content {
      padding: 2rem;
      @include breakpoint(phone) {
        padding: 1rem;
      }
    }

    .yui3-calendar-header {

      padding-bottom: 2rem;
      border-bottom: 1px solid $color-grey-light-5;

      &-label {
        text-transform: uppercase;
      }

      a{
        border: 0;
        &:before{
        position:absolute;
        content:"";
        border: solid $color-secondary;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 3px;
        top:13px;
        height: 1rem;
        width: 1rem;
      }


      &.yui3-calendarnav-prevmonth {
        top: 2rem;
        left: 3rem;
        margin: 0;
        transform: rotateZ(135deg);
      }
      &.yui3-calendarnav-nextmonth {
        transform: rotateZ(315deg);
        right: 3rem;
        margin: 0;
      }

    }
  }

    .yui3-u-1 { padding-top: 2.5rem;}

    .yui3-calendar-weekday {
      text-transform: capitalize;
      font-weight: 400;
      color: $color-grey-dark-1;
    }

    td {
      border: 0 !important;
      padding: 1rem 1.2rem !important;
      border-radius: 50%;
      background-color: inherit !important;
      &:hover {background: $color-secondary !important}
    }

    th {
      text-align: center;
    }

    .yui3-calendar-day-selected{
      background: $color-secondary !important;
      color: $color-white;
    }

  }
}

.sitemapDropIcon {
  margin-left: 3px;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
}

  //Alert Bar
  .alertBar{

    color: $color-white;
    text-transform: uppercase;
    position:relative;
    background-color: $color-green-flag;
    padding: 15px 15px 10px 84px;
    margin-bottom:2px;

    @include breakpoint(tablet) {
      padding-bottom:30px;
    }

    &>svg{
      position: absolute;
      width: 42px;
      height: 42px;
      top: 18px;
      left: 20px;
      color:$color-white;
      opacity: 0.5;
    }

    .alertBarTitle{
      display:block;
      font-size:$font-size-denary;
      padding-bottom:0;
      font-weight: bold;

      @include breakpoint(phone) {
        font-size:$font-size-tertiary;
      }
      a{
        color: #FFFFFF;
      }
    }
    .alertBarText{
      font-size:$font-size-primary;
      display: block;
      padding-top:2px;
      text-transform: none;
      @include breakpoint(phone) {
        font-size:$font-size-secondary;
      }
      a{
        color: #FFFFFF;
      }
    }

    // .alertBarLink{
    //   color: $color-white;
    //   font-size:$font-size-secondary;
    //   right:20px;
    //   bottom:10px;
    //   position: absolute;
    //   svg{
    //     padding-left:5px;
    //     width:13px;
    //     height:13px;
    //     padding-top:4px;
    //   }
    //   @include breakpoint(tablet) {
    //     right: 10px;
    //   }

    // }
  }
