@supports (position:-ms-page) {
    /* Microsoft EdgeV13&14 CSS styles go here */
    .box__invert--container {
        justify-content: center !important;
      }

      .mosaic {
        &-item {
          justify-content: space-around;
          padding: 2.5rem;
        }
    
        &__heading {
          width: 100%;
        }
      }
    
 }


 