#section-cookies {
    display: none;
    background-color: $color-grey-dark-3;
    height: 120px;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 4000;
    color: $color-white;

    @include breakpoint(phone) {
        height: auto;
      }

    .row { height: 100%;} 

    .cookies {
       
        &__left {
            justify-content: flex-end;
            padding:2rem;
         }

        &__right {
            justify-content: flex-start;
            
            @include breakpoint(phone) {
                   padding:2rem ;
                   padding-top: 0;
              }
        }
        
        &__left,
        &__right {
            display: flex;
            height: 100%;
            
            align-items: center;
            @include breakpoint(phone) {
                height: 50%;
                justify-content: center;
              }
        } 

        &__message {
           
        }

        &__link {
            text-decoration: underline;
            color: inherit;
        }

        &__button { 
            text-decoration: none;
            background: $color-secondary;
            color: inherit;
            padding: 1rem 2rem;

            svg {
                color: inherit;
                height: 20px;
                width: 20px; }
        }
    }
}