// IMAGES PATH
$images-path: "/src/assets/images";


// COLORS

$color-black: #2B363C;

$color-grey-dark-4: #999999;
$color-grey-dark-3: #333333;
$color-grey-dark-2: #808080;
$color-grey-dark-1: #999999;

$color-grey-light-9: #64718B;
$color-grey-light-8: #eee;
$color-grey-light-7: #e3e3e3;
$color-grey-light-6: #f5f5f5;
$color-grey-light-5: #e6e6e6;
$color-grey-light-4: #fafafa;
$color-grey-light-3: #cccccc;
$color-grey-light-2: #cccccc;
$color-grey-light-1: #F1F3F8;
$color-grey-light-0: #f9f9f9; 

$color-white: #fff; 

$color-red:#c1272d;
$color-green:#4ac34f;
$color-green-flag:#00724C;
$color-green-flag-dark:#094C23;

$color-secondary: #034AD8;  // rgb(24, 123, 158)
$color-secondary-dark: #18799b;
$color-primary: #ffd261; //  rgb(255, 210, 97)
$color-primary-light: #ffd66d;
$color-primary-dark: #a0d2f0;

// FONTS

$font-size-primary: 14px;
$font-size-secondary: 12px; 
$font-size-tertiary: 16px; 
$font-size-quaternary: 13px; 
$font-size-quinary: 11px;
$font-size-senary: 28px; 
$font-size-septenary: 18px; 
$font-size-octanary: 22px; 
$font-size-nonary: 38px; 
$font-size-denary: 20px; 



$header-height: 121px;
  $footer-height: 75px; 
  $footer-height-xs: 63px; 

// BOX SHADOW

$box-shadow-primary: 0px 0px 10px 1px rgba(0,0,0,0.2);