//BREAKPOINTS


@mixin breakpoint($class) {
   
    @if $class == smallest-phone {
      @media all and (max-width: 499px) { @content; 
      }
    }

    @if $class == small-phone {
      @media all and (max-width: 575px) { @content; }
    }
   
    @if $class == phone {
      @media all and (max-width: 767px) { @content; }
    }
   
    @if $class == tablet {
      @media all and (max-width: 991px) { @content; }
    }
   
    @if $class == desktop {
      @media all and (max-width: 1199px) { @content; }
    } 

    @if $class == large-desktop {
      @media all and (max-width: 1360px) { @content; }
    }
   
  }


@mixin size($width, $height...) {
  @if length($height) == 1 {
    height: $height;
    width: $width;
  } @else {
    height: $width;
    width: $width;
  }
}