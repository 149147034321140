// LIFERAY

.portlet__search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

// TEXT

.text-overflow {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


//COLORS

.bg-btn-color-secondary {
  background-image: -webkit-gradient(
    linear,
    left top, right top,
    from($color-secondary),
    color-stop(50%, $color-secondary),
    color-stop(50%, $color-secondary-dark)
  );
  background-image: linear-gradient(
    to right,
    $color-secondary 0%,
    $color-secondary 50%,
    $color-secondary-dark 50%
  );
}

.color-grey-light-4 {
  color: $color-grey-light-4 !important;
}

.color-grey-light-2 {
  color: $color-grey-light-2 !important;
}
.color-grey-light-1 {
  color: $color-grey-light-1 !important;
}

.color-grey-dark-3 {
  color: $color-grey-dark-3 !important;
}

.color-grey-dark-2 {
  color: $color-grey-dark-2 !important;
}
.color-grey-dark-1 {
  color: $color-grey-dark-1 !important;
}

.color-primary {
  color: $color-primary !important;
}

.color-secondary {
  color: $color-secondary !important;
}

.color-black {
  color: $color-black !important;
}

.color-red {
  color: $color-red !important;
}

//  BACKGROUND COLORS

.bg-color-white {
  background-color: $color-white !important;
}

.bg-color-grey-light-4 {
  background-color: $color-grey-light-4 !important;
}

.bg-color-grey-light-2 {
  background-color: $color-grey-light-2 !important;
}
.bg-color-grey-light-1 {
  background-color: $color-grey-light-1 !important;
}
.bg-color-grey-light-0 {
  background-color: $color-grey-light-0 !important;
}

.bg-color-grey-dark-3 {
  background-color: $color-grey-dark-3 !important;
}

.bg-color-grey-dark-2 {
  background-color: $color-grey-dark-2 !important;
}
.bg-color-grey-dark-1 {
  background-color: $color-grey-dark-1 !important;
}

.bg-color-primary {
  background-color: $color-primary !important;
}

.bg-color-secondary {
  background-color: $color-secondary !important;
}

/* DISPLAY*/


.d-sm-none {
  @include breakpoint(tablet) {
    display: none !important;
  }
}

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.flex-2 {
  -webkit-box-flex: 2;
      -ms-flex-positive: 2;
          flex-grow: 2;
}

.flex-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.flex-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.justify-content-around {
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.justify-content-between {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.justify-content-center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.justify-content-end {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.align-items-center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.align-self-center {
  -ms-flex-item-align: center;
      align-self: center;
}

.align-self-start {
  -ms-flex-item-align: start;
      align-self: flex-start;
}
.align-self-end {
  -ms-flex-item-align: end;
      align-self: flex-end;
}

.flex-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.flex-around-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.flex-between-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.flex__w-1-3 {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 33.3333333%;
          flex: 0 1 33.3333333%;
}

.flex__w-2-3 {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 66.666666%;
          flex: 0 1 66.666666%;
}

// FONTS

.font-size-primary {
  font-size: $font-size-primary;
}

.font-size-secondary {
  font-size: $font-size-secondary;
}

.font-size-tertiary {
  font-size: $font-size-tertiary;
}

.font-size-quinary {
  font-size: $font-size-quinary;
}

// FONT WEIGHT

.fw-900 {
  font-weight: 900;
}
.fw-600 {
  font-weight: 600;
}
.fw-400 {
  font-weight: 400;
}

//POSITIONING
.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-3 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-4 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-5 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-4 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-5 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.px-auto {
  padding-left: auto;
  padding-right: auto;
}
.py-auto {
  padding-top: auto;
  padding-bottom: auto;
}
.p-auto {
  padding: auto;
}
.p-0 {
  padding: 0;
}
.p-1 {
  padding: 0.25rem;
}
.p-2 {
  padding: 0.5rem;
}
.p-3 {
  padding: 1rem;
}
.p-4 {
  padding: 1.5rem;
}
.p-5 {
  padding: 3rem;
}

.pl-auto {
  padding-left: auto;
}
.pl-0 {
  padding-left: 0 !important;
}

.pl-0-t{
  @include breakpoint(tablet) {
    padding-left: 0 !important;
  }
}

.pl-1 {
  padding-left: 0.25rem;
}
.pl-2 {
  padding-left: 0.5rem;
}
.pl-3 {
  padding-left: 1rem;
}

.pl-4 {
  padding-left: 1.5rem;
}

.pl-4-5 {
  padding-left: 2.5rem !important;

}

.pl-5 {
  padding-left: 3rem;
}

.pr-auto {
  padding-right: auto;
}
.pr-0 {
  padding-right: 0;
}
.pr-1 {
  padding-right: 0.25rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
.pr-3 {
  padding-right: 1rem;
}
.pr-4 {
  padding-right: 1.5rem;
}
.pr-5 {
  padding-right: 3rem;
}

.pt-0 {
  padding-top: 0;
}
.pt-1 {
  padding-top: 0.25rem; 
}
.pt-2 {
  padding-top: 0.5rem;
}
.pt-3 {
  padding-top: 1rem;
}
.pt-4 {
  padding-top: 1.5rem;
}
.pt-5 {
  padding-top: 3rem !important;
}

.pb-0 {
  padding-bottom: 0;
}
.pb-1 {
  padding-bottom: 0.25rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pb-3 {
  padding-bottom: 1rem;
}
.pb-4 {
  padding-bottom: 1.5rem;
}
.pb-5 {
  padding-bottom: 3rem !important;
}

//MARGIN

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mx-3 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-4 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-5 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-4 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-5 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.m-0 {
  margin: 0;
}
.m-1 {
  margin: 0.25rem;
}
.m-2 {
  margin: 5rem;
}
.m-3 {
  margin: 1rem;
}
.m-4 {
  margin: 1.5rem;
}
.m-5 {
  margin: 3rem;
}

.ml-0 {
  margin-left: 0;
}
.ml-1 {
  margin-left: 0.25rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.ml-3 {
  margin-left: 1rem;
}
.ml-4 {
  margin-left: 1.5rem;
}
.ml-5 {
  margin-left: 3rem;
}

.mr-0 {
  margin-right: 0;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mr-3 {
  margin-right: 1rem;
}
.mr-4 {
  margin-right: 1.5rem;
}
.mr-5 {
  margin-right: 3rem;
}

.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-3 {
  margin-top: 1rem;
}
.mt-4 {
  margin-top: 1.5rem;
}
.mt-5 {
  margin-top: 3rem !important;
}
.mt-6 {
  margin-top: 3.5rem !important;
}
.mt-7 {
  margin-top: 4rem !important;
}

.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-3 {
  margin-bottom: 1rem;
}
.mb-4 {
  margin-bottom: 1.5rem;
}
.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-6 {
  margin-bottom: 4rem;
}

.mb-7 {
  margin-bottom: 5rem !important;
}

// BORDER

.border-0 {
  border: none;
}

.border__mosaic {
  border: 1px solid #337ab740;
}

.border__mosaic--right {
 position: relative;
 @include breakpoint(tablet) {
  margin-bottom: 2rem !important;
}
}

.border__mosaic--right::before {
  content: '';
  border-left: 1px solid $color-grey-light-3;
  position: absolute;
  height: 75%;
  right: 0.25rem;
  top: 13%;

  @include breakpoint(tablet) {
    border-left: none;
    border-bottom: 1px solid $color-grey-light-3;
    width: 95%;
    right: 3%;
    top:30%;
  }
}
// SIZING

.h-100 {
  height: 100%;
}
.h-75 {
  height: 75%;
}
.h-50 {
  height: 50%;
}

.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.w-60{
  width: 60%;
}

.w-50 {
  width: 50%;
}

// TEXT

.text-white {
  color: $color-white !important;
}

//FORM

.form-control {
  -webkit-box-shadow: none;
          box-shadow: none;
}

//SIZE

.size-10 {
  @include size(10px, 10px);
}

.size-12 {
  @include size(12px, 12px);
}
.size-14 {
  @include size(14px, 14px);
}

.size-15 {
  @include size(15px, 15px);
}
.size-17 {
  @include size(17px, 17px);
}

.size-20 {
  @include size(20px, 20px);
}
.size-22 {
  @include size(22px, 22px);
}

//ROTATION

.rotate-z-180 {
  -webkit-transform: rotateZ(180deg);
          transform: rotateZ(180deg);
}

.rotate-z-270 {
  -webkit-transform: rotateZ(270deg);
          transform: rotateZ(270deg);
}

.rotate-z-315 {
  -webkit-transform: rotateZ(315deg);
          transform: rotateZ(315deg);
}


//HOVER

.hover-opac-9 {
  &:hover {opacity: .9 !important;}
}

.hover-opac-8 {
  &:hover {opacity: .8 !important;}
}

.hover-opac-7 {
  &:hover {
    opacity: .7 !important;
  }
}

.hover-opac-back-7 {
  &:hover {
    background-color: rgba(57, 57, 57, .9)!important;
  }
}

.text-Upp {
  text-transform: uppercase;
}

.float-r {
  float: right;
}

.float-l {
  float: left;
}

.fs-0 {
  flex-shrink: 0;
}
