
html {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
     
  }

  *,
  *::before,
  *::after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
    line-height: 23px; 
  }
  
  body {
    
    // standard placeholder 
    input::-webkit-input-placeholder, textarea::-webkit-input-placeholder{color:$color-grey-light-9; font-style:italic}
    input:-moz-placeholder, textarea:-moz-placeholder{color:$color-grey-light-9; opacity:1; font-style:italic}
    input::-moz-placeholder, textarea::-moz-placeholder{color:$color-grey-light-9; opacity:1; font-style:italic}
    input:-ms-input-placeholder, textarea:-ms-input-placeholder{color:$color-grey-light-9; font-style:italic}

  }

  //RESET HEADINGS

  h1, h2, h3, h4, h5, h6 {
    font-size: inherit;
    font-weight: inherit;
    margin: 0;
  }
  

  p, a {
    margin: 0;
    padding: 0;
  }

  a { color: $color-secondary}

  a:hover, a:focus {
    color: $color-grey-dark-3;
    text-decoration: underline; }

  ul > li {
    list-style: none;
    padding: 0;
    margin: 0;
  } 



  button:hover {
    cursor: pointer;
  }

  .row {
    margin: 0; 
  }

.guias {

  min-height: 550px;
  
  .col-md-7 {
    div:empty {
      display: none;
    }
  }
}

.img404 {
  height: calc(100vh - 260px);
  position: static;
  background: url(#{$images-path}/404/error_404.png);
  background-size: cover;                    
  background-repeat: no-repeat;
  background-position: center center;    
  padding-left: 0;
  padding-right: 0;
  padding-top: 6rem;
    .bar404 {
      width: 100%;
      padding-top: 3rem;
      padding-bottom: 2rem;
      background-color: $color-secondary;
      text-align: center;
      color: $color-white;
      a{
        color: $color-white;
      }
      .barline1{
        font-size: $font-size-nonary;
        font-family: 'Noto Sans', sans-serif;
        text-transform: uppercase;
      }
      .barline2{
        font-size: $font-size-primary;
        font-family: 'Noto Sans', sans-serif;
        font-weight: 600;
        margin-top: 2rem;
      }
      .barline3{
        font-size: $font-size-primary;
        font-family: 'Noto Sans', sans-serif;
      }
    }
}



 